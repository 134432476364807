<template>
  <main class="RoutesForm">
    <TheSubHeader
      icon="package-delivery-alt"
      title="Nova Rota"
      page-title-tag="Routes Form"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="340px 1fr 100px 1fr 160px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Período"
          type="datetime"
        />
        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="form.store_id"
          name="Lojista ou Hub"
          label="Lojista ou Hub"
          placeholder="Selecione um Lojista ou Hub"
          :select-options="null"
          :fetch-options="'routes/fetchTransitPointImportAsync'"
          @input="filterStores"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.status"
          class="RoutesForm__status"
          name="Status"
          label="Status"
          placeholder="Status"
          :clearable="false"
          :select-options="filterStatusOptions"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pesquisar"
          label="Pesquisar"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          class="RoutesForm__btn"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag v-if="allTags.length" :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <section class="RoutesForm__orders">
      <div class="RoutesForm__ordersContent">
        <div class="RoutesForm__table">
          <DataTable
            v-model="form.order_ids"
            class="RoutesForm__table--dataTable"
            :header="ordersWithoutRoutesFilteredHeader"
            :table="ordersWithoutRoutes.data.content"
            @input="getTotalInvoicesValues"
          />
          <div class="RoutesForm__table--confirm">
            <div class="RoutesForm__table--information">
              <p>{{ ordersSelectedText }}</p>
              <p>{{ invoicesValues }}</p>
            </div>
            
            <DynamicForm
              ref="form"
              class="RoutesForm__form"
              :model="form"
              :blueprint="blueprint({hideFields, save, todayParsed, storeOrHub: form.store_id !== null ? form.store_id : null, transitPointImport, routesNameByStore})"
              :endpoint-errors="endpointErrors"
              submit-type="json"
              @filterDrivers="search()"
              @submit="onSubmit"
            />
          </div>
          <Pagination class="RoutesForm__table--pagination" @page="search()" @paginate="search()" />
        </div>
      </div>
    </section>
    <Dialog
      v-if="showMissingOrders"
      class="RoutesForm__dialog"
      :warning="missingOrdersText"
      :label-cancel="false"
      label-confirm="Confirmar"
      @confirm="removeMissingOrders"
    >
      <BaseList :list="missingOrders" :title="'Nome do Pedido'" :scrollbar="true" scrollbar-height="25vh" />
    </Dialog>
    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { routes } from '@/api'
import { mapActions, mapState, mapGetters } from 'vuex'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { DynamicForm, Pagination, DataTable, FiltersTag, Dialog, BarcodeScannerSettings, BaseList } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './RoutesForm.blueprint'
import Mask from '@/plugins/mask/Mask.js'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'

export default {
  name: 'RoutesForm',
  components: {
    BaseList,
    DataTable,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    TheSubHeader,
    DynamicForm,
    Pagination,
    FiltersTag,
    Dialog,
    BarcodeScannerSettings,
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      allTags: [],
      form: {
        driver_id: null,
        store_id: null,
        transit_point_id: null,
        collect_date: null,
        cte_emit: false,
        name: null,
        notify: {
          id: 1,
          name: 'Sim' 
        },
        order_ids: [],
      },
      filters: {
        query: [],
        dateRange: {
          init: null,
          end: null,
        },
        status: {id: 0, name: 'Todos'}
      },
      notifyOptions: [
        {
          id: 1,
          name: 'Sim' 
        },
        {
          id: 0,
          name: 'Não' 
        },
      ],
      
      filterStatusOptions: [
        {id: 0, name: 'Todos'},
        {id: 1, name: 'Pendente' },
        {id: 3, name: 'Aguardando Roteirização'}
      ],
      endpointErrors: {},
      showMissingOrders: false,
      missingOrders: [],
      missingOrdersText: '',
      invoicesValues: '',
      firstSearch: true,
      itsRouteWithCte: false
    }
  },

  computed: {
    ...mapState({
      ordersWithoutRoutes: state => state.routes.ordersWithoutRoutes,
      routesNameByStore: state => state.routes.routesNameByStore,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      storesImport: state => state.stores.storesImport,
    }),

    ...mapGetters({
      transitPointImport: 'routes/transitPointImportWithoutParentStores'
    }),

    //Hide Fields
    hideFields() {
      return {
        cteEmit: this.itsRouteWithCte
      }
    },

    parsedFormStoreId() {
      if (this.form.store_id && !this.form.store_id.schedule) {
        let stores = []
        stores.push({
          id: this.form.store_id.id
        })
        return stores
      } else if (this.form.store_id && this.form.store_id.schedule) {
        return this.form.store_id.stores.map(m => {
          return {
            id: m.id
          }
        })
      } else {
        return []
      }
    },

    save(){
      return !this.form.order_ids.length
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    ordersWithoutRoutesFilteredHeader() {
      let o = this.ordersWithoutRoutes.data.headers
      if (o.created_at && o.created_at.method === 'millisecondsToDate') {
        o.created_at.method = 'millisecondsToDateHour'
      }
      return o
    },

    ordersSelectedText() {
      let text
      let total = this.form.order_ids.length
      if (total > 0) {
        text = `${total} pedido${total === 1 ? '' : 's'} selecionado${total === 1 ? '' : 's'}`
      } else {
        text = `Marque nas caixas de seleção ${window.innerWidth < 960 ? 'abaixo' : 'acima'} os pedidos para inserir na nova rota.`
      }
      return text
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    parsedStatus() {
      const status = []
      const id = this.filters.status ? this.filters.status.id : null
      switch (id) {
      case 1:
        status.push({id: '__const:storeStatusType__pendente', name: 'Pendente' })
        break
      case 3:
        status.push({id: '__const:storeStatusType__aguardandoRoteirizacao', name: 'Aguardando Roteirização'})
        break
      default:
        status.push({id: '__const:storeStatusType__pendente', name: 'Pendente' })
        status.push({id: '__const:storeStatusType__aguardandoRoteirizacao', name: 'Aguardando Roteirização'})
        
      }
      return status
    }
  },

  async mounted() {
    this.resetSearch()
  },

  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchOrdersWithoutRoutes: 'routes/fetchOrdersWithoutRoutes',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      fetchRoutesNameByStores: 'routes/fetchRoutesNameByStores',
      setError: 'error/setError',
    }),

    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    async filterStores(data){
      await this.filterBarcodeSettings(data, this.callbackBarcodeScanned)
      this.setStoreCteEmitted(data)
      await this.setFetchRoutesNameByStores(data)
      this.form.driver_id = null
      this.resetSearch()
    },

    setStoreCteEmitted(data) {
      if(data && data.info) {
        this.form.cte_emit = data.info.cte_emit
        this.itsRouteWithCte = data.info.cte_emit
      } else {
        this.form.cte_emit = false
        this.itsRouteWithCte = false
      }
    },

    async setFetchRoutesNameByStores(data) {
      if(data) {
        //type - 1 Store / 2 Hub
        this.form.name = null
        const obj = {
          type: data && data.schedule ? 2 : 1,
          id: data && data.schedule ? data.id.replace('H-', '') : data.id
        }
        await this.fetchRoutesNameByStores(obj)
      }
    },

    updateTags(){
      this.allTags = [...this.filters.query]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id.toLowerCase() !== id.toLowerCase())
      this.filters.query = this.filters.query.filter((tag) => tag.name.toLowerCase() !== name.toLowerCase())      
    },

    clearAllTags(){
      this.allTags = []
      this.filters.query = []
    },

    checkIfOrdersExist() {
      let query = [...new Set(this.filters.query.map(q => q.name))]
      let ordersNumber = this.ordersWithoutRoutes.data.content.map(order => order.order_number != null ? order.order_number.toLowerCase() : order.order_number)
      let ordersInvoiceNumber = this.ordersWithoutRoutes.data.content.map(order => order.invoice_number != null ? order.invoice_number.toLowerCase() : order.invoice_number)

      this.missingOrders = query.filter(f => !ordersNumber.includes(f.toLowerCase()) && !ordersInvoiceNumber.includes(f.toLowerCase()))
      if (this.missingOrders.length > 0) {
        if (this.missingOrders.length > 1) {
          this.missingOrdersText = 'Alguns pedidos de sua pesquisa não foram encontrados no sistema com base nos filtros que você escolheu ou não estão com o status apto para serem roteirizados:'
        } else {
          this.missingOrdersText = 'Um pedido da sua pesquisa não foi encontrado no sistema com base nos filtros que você escolheu ou não está com o status apto para ser roteirizado:'
        }
        this.showMissingOrders = true
        if (!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(false)
      } else {
        if(!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(true)
      }
    },

    removeMissingOrders() {
      let m = this.missingOrders
      for (let i = 0; i < m.length; i++) {
        this.removeTag(m[i], m[i])
        this.$refs.query.removeTag(m[i])
      }
      this.showMissingOrders = false
    },

    resetSearch() {
      this.form.order_ids = []
      this.form.driver_id = null
      this.invoicesValues = ''
      this.clearPagination(this.filters.query.length > 50 ? this.filters.query.length : 50).then(() => this.search())
    },

    async search() {
      const params = {
        obj: {
          date_hour_start: this.filters.dateRange.init,
          date_hour_end: this.filters.dateRange.end,
          query: this.filters.query,
          'filter[status][store_status_type_id]': this.parsedStatus,
          'driver_id': this.form.driver_id && this.form.driver_id !== '' ? this.form.driver_id.id : null, 
          'store_id': this.parsedFormStoreId
        },
        paginate: this.paginate,
        page: this.page
      }
      
      await this.fetchOrdersWithoutRoutes(params)
        .then(() => {
          let ordersReturned = this.ordersWithoutRoutes.data.content.map(order => order.id)
          this.form.order_ids = this.form.order_ids.filter(order => ordersReturned.includes(order.id))
          this.checkIfOrdersExist()
          this.setPagination(this.ordersWithoutRoutes)
          this.firstSearch = false
        })

      this.setLoading(false)
    
    },

    async onSubmit(data) {
      this.setLoading(true)
      let body = this.modifyStoreOrHub(data)
      await routes.createRoute(body, () => this.$router.push('/routes'), (e) => {
        if (e?.validation?.order_ids?.length) {
          this.setError(e?.validation?.order_ids.join('<br />'))
        }
        this.endpointErrors = e.validation
      }, () => this.setLoading(false))
    },

    modifyStoreOrHub() {
      const obj = Object.assign({}, this.form)
      if (obj?.store_id?.schedule) {
        obj.transit_point_id = obj?.store_id?.id.replace('H-', '')
        delete obj.store_id
      } else {
        obj.store_id = obj.store_id?.id
        delete obj.transit_point_id
      }
      return obj
    },

    getTotalInvoicesValues() {
      const invoicesData = this.form.order_ids.map((el) => el.invoiceValue)

      if (!invoicesData.length) {
        this.invoicesValues = ''
      }
      
      const hasNull = invoicesData.some((el) => {
        return el === null
      })

      if (hasNull) {
        this.invoicesValues = 'Uma ou mais notas não possuem valor descrito, por isso o total não pôde ser calculado'
        return
      } 

      const getInvoiceValues = (total, item) => {
        return total + item
      }

      const totalInvoicesValues = invoicesData.reduce(getInvoiceValues, 0)

      if (totalInvoicesValues > 0) {
        this.invoicesValues = 'Valor total da nota ' + Mask.money(totalInvoicesValues)
      } 
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.RoutesForm {
  max-width: 100vw;

  /deep/.Organizer__filter{
    align-items: flex-end;
  }

  &__content,
  &__orders {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  &__form,
  &__ordersContent {
    grid-area: main;
  }

  &__form {
    grid-row-gap: 1rem;
    grid-template-areas: 
      'store_id store_id store_id driver_id driver_id driver_id collect_date collect_date collect_date name name name'
      '. . . . . . . . cte_emit cte_emit confirm confirm ';
    align-items: flex-end;
    @media #{$mobile-view} {
      width: 100%;
    }
  }

  &__dialog{
    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
   
  }
  

  &__ordersContent {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas:
      "filter"
      "table";
    @media #{$mobile-view} {
      grid-template-columns: 1fr;
      grid-template-areas:
        "filter"
        "table";
    }
  }
  &__filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem 1rem;
    align-items: flex-end;
  }
  // &__dateRange { max-width: 380px; }
  // &__status { max-width: 200px; }
  &__break {
    flex-basis: 100%;
    height: 0;
  }
  &__drivers { width: auto; flex-grow: 1; }
  // &__btn { width: 185px }
  @media #{$mobile-view} {
    &__dateRange,
    &__status,
    &__drivers,
    &__btn { width: 100%; max-width: initial; }
  }

  &__table {
    grid-area: table;
    display: flex;
    flex-direction: column;

    &--dataTable {
      @media #{$mobile-view} {
        order: 1;
        position: relative;
      }
    }

    &--pagination {
      @media #{$mobile-view} {
        order: 2;
        position: relative;
      }
    }

    &--confirm {
      position: sticky;
      bottom: 0;
      padding: 15px 0 30px;
      background: white;
      z-index: $z-active;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media #{$mobile-view} {
        padding: 0;
        margin-top: 0;
        margin-bottom: 20px;
        order: 0;
        position: relative;
      }

      p {
        font-size: $font-size-3xsmall;
        margin: 0 0 15px 0;
        padding: 0;
      }
      &:before {
        content: '';
        width: 300vw;
        display: block;
        position: absolute;
        height: 50px;
        bottom: 100%;
        left: -100vw;
        @media #{$mobile-view} {
          display: none;
        }
        pointer-events: none;
        background: linear-gradient(
          to top,
          rgba($color-neutral-stronger, 0.1) 0%,
          rgba($color-neutral-stronger, 0) 50%
        );
      }
      @media #{$mobile-view} {
        flex-direction: column;
        width: 100%;
          z-index: 4001
      }
    }

    &--information {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>