export default ({ hideFields, save, todayParsed, storeOrHub, routesNameByStore }) => {
  const routeNameMultSelect = [
    {
      id: 'name',
      model: 'name',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Título da Rota',
        label: 'Título da Rota',
        placeholder: 'Selecione um nome para a rota',
        invalidLabel: 'Insira um nome para a rota',
        selectOptions: routesNameByStore,
        openDirection: window.innerWidth < 960 ? 'bottom' : 'top'
      }
    }
  ]

  const routeNameInput = [
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Título da Rota',
        invalidLabel: 'Insira um nome para a rota',
        readOnly: !storeOrHub
      }
    },
  ]

  const routeCteEmit = [
    {
      id: 'cte_emit',
      model: 'cte_emit',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Essa rota emite CTE?',
        color: 'yellow',
        marker: 'check',
        disabled: true
      }
    }
  ]

  return [
    {
      id: 'store_id',
      model: 'store_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Local de Coleta',
        label: 'Local de Coleta',
        placeholder: 'Selecione um Lojista ou Hub',
        selectOptions: null,
        fetchOptions: 'routes/fetchTransitPointImportAsync',
      },
      events: {
        input: 'filterStores'
      }
    },
    {
      id: 'driver_id',
      model: 'driver_id',
      tag: 'BaseTreeSelect',
      props: {
        name: 'Atribuir Motorista',
        label: 'Atribuir Motorista',
        placeholder: 'Pesquisar',
        suggestions: storeOrHub && storeOrHub.schedule ? 'searchDriversWithTransitPoint' : 'searchDriversWithStores',
        suggestionsParams: storeOrHub ? storeOrHub.schedule ? storeOrHub.id.replace('H-', '') : storeOrHub.id : null,
        readOnly: !storeOrHub,
        cacheOptions: false,
        hasAlert: true
      },
      events: {
        input: 'filterDrivers'
      }
    },   
    {
      id: 'collect_date',
      model: 'collect_date',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Data e Hora da Coleta',
        format: 'DD/MM/YYYY HH:mm',
        notBefore: todayParsed,
        type: 'datetime',
        prependIcon: false,
        range: false,
        placeholder: '',
        invalidLabel: 'Insira uma data',
      }
    },
    ...(routesNameByStore && routesNameByStore.length > 0 ? routeNameMultSelect: routeNameInput),
    ...(hideFields.cteEmit ? routeCteEmit : []),

  
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Confirmar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true,
        disabled: save
      }
    }
  ]
}